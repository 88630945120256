<template>
  <div class="fluid-container">
    <router-link class="link" to="/contact">
      <div class="footer">Get in touch</div>
    </router-link>
    <router-link class="link" to="/">
    <div class="footer">&copy; 2023 ghoulchat</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.fluid-container {
  /* position: fixabsoed; */
  margin-top: 80px;
  color: #fff;
  position: relative;
  bottom: 0;
  width: 100%;
  /* height: 2.5rem;     */
}

.footer,
.link {
  font-weight: 400;
  text-decoration: none;
  color: black;
}

.footer:hover {
  color: #4cd964;
}
</style>
